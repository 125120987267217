import React, { useState, useEffect } from 'react';

import '../styles/AnimatedLink.css';

function AnimatedLink({ text, url, className }) {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const letters = document.querySelectorAll('.animated-link span');
    letters.forEach((letter, index) => {
      letter.style.animationDelay = `${index * 100}ms`;
    });
  }, []);

  return (
    <a
      href={url}
      className={`animated-link ${className}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {text.split('').map((letter, index) => (
        <span key={index} className={hovered ? 'hovered' : ''}>
          {letter === ' ' ? '\u00A0' : letter}
        </span>
      ))}
    </a>
  );
}

export default AnimatedLink;
