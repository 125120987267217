import React from 'react'
import './styles/ModalForms.css';

function ModalForms({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close" onClick={onClose} />
        <p>{message}</p>
      </div>
    </div>

  )
}

export default ModalForms