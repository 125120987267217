import React, { useState, useEffect } from 'react'
import NavBar from './components/NavBar/NavBar'
import PrivacyModal from '../../components/PrivacyModal/PrivacyModal';
import PrivacyPolicy from '../../components/PrivacyModal/styles/PrivacyPolicy/styles/PrivacyPolicy';

import './styles/LandingPage.css';

import Section1 from './components/Section1/components/Section1';
import Section2 from './components/Section2/components/Section2';
import Section3 from './components/Section3/components/Section3';
import Section4 from './components/Section4/components/Section4';

import Footer from './components/Footer/components/Footer';

function LandingPage() {
  const [showModal, setShowModal] = useState(true);
  const [message, setMessage] = useState(
    `Utilizamos cookies para melhorar sua experiência no nosso site. 
    Ao continuar navegando, você concorda com nossa política de privacidade e uso de cookies.`
  );
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const privacyMessage = (
    <span>
      Utilizamos cookies para melhorar sua experiência no nosso site. Ao continuar navegando, você concorda com nossa <a href="/privacy" onClick={() => setShowPrivacyPolicy(true)}>Política de Privacidade</a> e uso de cookies.
    </span>
  );
  const policy = (<a className='policy' href="/privacy" onClick={() => setShowPrivacyPolicy(true)}>Política de Privacidade</a>)

  useEffect(() => {
    const accepted = localStorage.getItem('privacyAccepted');
    if (!accepted) {
      setShowModal(true);
    }
  }, []);

  const handleAccept = () => {
    setShowModal(false);
    localStorage.setItem('privacyAccepted', 'true');

  };

  const handleDecline = () => {
    setShowModal(true);
    localStorage.removeItem('privacyAccepted');
    setMessage(privacyMessage);
  }

  return (
    <>


      <h1>Bem-vindo ao nosso site!</h1>
      <div className="container">
        <NavBar />
      </div>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />

      <Footer />
      {showModal && <PrivacyModal policy={policy} message={message} onAccept={handleAccept} onDecline={handleDecline} />}
      {showPrivacyPolicy && <PrivacyPolicy />}
    </>

  )
}

export default LandingPage