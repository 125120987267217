import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useInView } from 'react-intersection-observer';
import { subscriber } from '../../../../../service/api';
import '../styles/Section4.css';

import { BiMailSend } from "react-icons/bi";
import mobileInteraction from '../../../../../assets/img/mobileInteraction.jpg';

function Section4() {
  const [loading, setLoading] = useState(false);
  const [messageContact, setMessageContact] = useState('');
  const [ellipsis, setEllipsis] = useState('.....');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    comments: '',
  });
  const [errors, setErrors] = useState('');

  const { ref: formRef, inView: isFormInView } = useInView({ triggerOnce: false });

  useEffect(() => {
    if (!isFormInView) {
      resetForm();
    }
  }, [isFormInView]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let valid = true;

    if (!formData.name) {
      formErrors.name = 'Nome é necessário';
      valid = false;
    }
    if (!formData.email) {
      formErrors.email = 'Email é necessário';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email inválido';
      valid = false;
    }
    if (!formData.phone) {
      formErrors.phone = 'Telefone é necessário';
      valid = false;
    }
    if (!formData.comments) {
      formErrors.comments = 'Por favor, deixe um comentário';
      valid = false;
    }

    setErrors(formErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      try {
        const response = await subscriber(formData);

        if (response) {
          setMessageContact(response.message || 'Formulário enviado com sucesso!');
        } else {
          setMessageContact('Erro ao enviar o formulário');
        }
      } catch (error) {
        if (error.response?.data) {
          setMessageContact(error.response.data.message || 'Erro ao enviar o formulário');
        } else {
          setMessageContact('Erro ao enviar o formulário');
        }
      } finally {
        setLoading(false);
        resetForm();
      }
    }
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      comments: '',
    });
    setErrors({});
  };

  useEffect(() => {
    if (messageContact) {
      const timer = setTimeout(() => {
        setMessageContact('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [messageContact]);

  useEffect(() => {
    let interval;
    if (loading) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis('.....');
    }

    return () => clearInterval(interval);
  }, [loading]);

  return (
    <>
      <div id="contato" className="section4">
        <div className="column">
          <img src={mobileInteraction} alt="pessoa usando aplicativo" />
          <span className="mission">
            Nosso objetivo é criar uma comunidade forte e unida, onde cada usuário possa encontrar e oferecer soluções de forma rápida e eficiente, mantendo viva a essência da cultura brasileira, não importa onde estejam.
          </span>
        </div>

        <div className="column">
          <span className="overview">
            A LinkBR é um aplicativo inovador com a missão de conectar brasileiros que vivem fora do país, proporcionando acesso a uma vasta gama de serviços e produtos em sua região local.
          </span>

          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="formLine">
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'error' : ''}
                placeholder={errors.name || 'Nome completo'}
                onFocus={() => setErrors({ ...errors, name: '' })}
                required
              />
            </div>
            <div className="formLine">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'error' : ''}
                placeholder={errors.email || 'Seu melhor email'}
                onFocus={() => setErrors({ ...errors, email: '' })}
                required
              />
            </div>
            <div className="formLine">
              <label htmlFor="phone">Telefone</label>
              <InputMask
                mask="(99) 99999-9999"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className={errors.phone ? 'error' : ''}
                placeholder={errors.phone || 'Número de telefone'}
                onFocus={() => setErrors({ ...errors, phone: '' })}
              />
            </div>
            <div className="formLine">
              <label htmlFor="comments">Comentários</label>
              <textarea
                id="comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                className={errors.comments ? 'error' : ''}
                placeholder={errors.comments || 'Deixe seus comentários'}
                onFocus={() => setErrors({ ...errors, comments: '' })}
                required
              />
            </div>
            <div className="form-message">
              {loading && (<div className="messageContact"><BiMailSend className="icoSend" /> {ellipsis}</div>)}
              {messageContact && <div className=""> {messageContact} </div>}
            </div>
            <div className="send-button">
              <button type="submit">Quero participar</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Section4;
