import React from 'react';
import { useInView } from 'react-intersection-observer';

import '../sytles/Section1.css';
import '../sytles/Section1Animation.css';

import logo512 from '../../../../../assets/img/navLogo.png';

function Section1() {
  const { ref: refH1, inView: inViewH1 } = useInView({ triggerOnce: false });
  const { ref: refSpan, inView: inViewSpan } = useInView({ triggerOnce: false });
  const { ref: refH2, inView: inViewH2 } = useInView({ triggerOnce: false });
  const { ref: refP, inView: inViewP } = useInView({ triggerOnce: false });
  const { ref: refImg, inView: inViewImg } = useInView({ triggerOnce: false });

  return (
    <div id="top" className="section1">
      <div className="column">
        <h1 ref={refH1} className={inViewH1 ? 'animate-left' : ''}>Encontre</h1>
        <span id='span' ref={refSpan} className={inViewSpan ? 'animate-right' : ''}>tudo do</span>
        <h2 ref={refH2} className={inViewH2 ? 'animate-left' : ''}>Brasil</h2>
        <p ref={refP} className={inViewP ? 'animate-right' : ''}>e do Mundo</p>
      </div>
      <div className="column">
        <img id="img" ref={refImg} src={logo512} alt="Logo" className={inViewImg ? 'animate-scale' : ''} />
      </div>
    </div>
  );
}

export default Section1;
