import React from 'react';
import './styles/PrivacyModal.css';

function PrivacyModal({ onAccept, onDecline, message, policy }) {

  return (
    <div className="privacy-modal-overlay">
      <div className="privacy-modal-content">
        <h2>{policy} e Utilização de Cookies</h2>
        <p>
          {message}
        </p>
        <div className="rowBtn">
          <button className='send-button' onClick={onAccept}>Aceitar</button>
          {/* <button className='decline-button' onClick={onDecline}>Recusar</button> */}
        </div>

      </div>
    </div>
  );
}

export default PrivacyModal;
