import React from 'react'

import logo from '../../../assets/img/navLogo.png';

import AnimatedLink from './AnimatedLink.js';

import '../styles/Sign.css';
import '../../WebSite_LandingPage/Mobile.css';

function Sign() {
  return (
    <div className="sign">
      <div className="colA"></div>
      <div className="colB"></div>
      <div className="colC"></div>
      <div className="colD"></div>
      <div className="colE">
        <img src={logo} alt="" srcset="" />
      </div>
      <div className="colF">
        <h1>Os Brasileiros e muitos outros estáo aqui,<br /> vem junto :)</h1>
        <h2>Login</h2>
        <form>
          <input type="text" placeholder="E-mail" />
          <input type="text" placeholder="Nome" />
          <input type="text" placeholder="Telefone" />
          <textarea type="text" placeholder="Comentários" />
          <button className='send-button' type="submit">enviar</button>
          <p>ou</p>
          <AnimatedLink className="links" text="voltar" url="/" />
        </form>
      </div>
    </div>
  )
}

export default Sign