import React from 'react';
import './PrivacyPolicy.css';
import NavBar from '../../../../../pages/WebSite_LandingPage/components/NavBar/NavBar';

const PrivacyPolicy = () => {
  return (
    <>
      <NavBar simplified={true} />
      <div className="privacy-policy">
        <h1>Política de Privacidade</h1>
        <p><strong>Última atualização: 05/2024</strong></p>
        <p>
          A LinkBR Aplicativos e Consultoria em T.I, doravante denominada <span>link<span className='B'>B</span><span className='R'>R</span> APP</span> valoriza a privacidade dos seus usuários e está comprometida em proteger os dados pessoais que coletamos. Este documento de Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos as informações dos nossos usuários. Ao utilizar nosso aplicativo, você concorda com as práticas descritas nesta política.
        </p>

        <h2>1. Informações que Coletamos</h2>

        <h3>1.1 Informações Pessoais</h3>
        <p>Podemos coletar as seguintes informações pessoais quando você se registra ou utiliza nosso aplicativo:</p>
        <ul>
          <li>Nome</li>
          <li>Endereço de e-mail</li>
          <li>Número de telefone</li>
          <li>Localização geográfica</li>
          <li>Informações de perfil (como foto e descrição)</li>
        </ul>

        <h3>1.2 Informações de Uso</h3>
        <p>Coletamos informações sobre como você utiliza nosso aplicativo, incluindo:</p>
        <ul>
          <li>Histórico de navegação e uso</li>
          <li>Dados de transações e interações com outros usuários</li>
          <li>Informações do dispositivo (como modelo, sistema operacional, identificadores únicos)</li>
        </ul>

        <h3>1.3 Cookies e Tecnologias Semelhantes</h3>
        <p>Utilizamos cookies e tecnologias semelhantes para coletar informações sobre sua atividade de navegação. Isso nos ajuda a personalizar sua experiência e melhorar nossos serviços.</p>

        <h2>2. Como Usamos Suas Informações</h2>

        <h3>2.1 Prestação de Serviços</h3>
        <p>Utilizamos suas informações para:</p>
        <ul>
          <li>Facilitar a conexão entre tomadores e consumidores de produtos e serviços e prestadores e fornecedores</li>
          <li>Personalizar e melhorar sua experiência no aplicativo</li>
          <li>Processar transações e comunicações entre usuários</li>
        </ul>

        <h3>2.2 Comunicação</h3>
        <p>Podemos utilizar suas informações de contato para:</p>
        <ul>
          <li>Enviar notificações e atualizações importantes</li>
          <li>Responder às suas perguntas e solicitações de suporte</li>
        </ul>

        <h3>2.3 Análise e Melhoria</h3>
        <p>Analisamos as informações coletadas para entender como nosso aplicativo é usado e identificar áreas de melhoria.</p>

        <h2>3. Compartilhamento de Informações</h2>

        <h3>3.1 Com Prestadores de Serviços</h3>
        <p>Podemos compartilhar suas informações com terceiros que nos auxiliam na operação do aplicativo, desde que esses terceiros concordem em manter a confidencialidade das suas informações.</p>

        <h3>3.2 Com Outros Usuários</h3>
        <p>Informações como seu nome, foto de perfil e localização podem ser visíveis para outros usuários do aplicativo para facilitar a conexão e a comunicação.</p>

        <h3>3.3 Requisitos Legais</h3>
        <p>Podemos divulgar suas informações se formos obrigados por lei ou se acreditarmos que tal ação é necessária para:</p>
        <ul>
          <li>Cumprir uma obrigação legal</li>
          <li>Proteger e defender nossos direitos ou propriedades</li>
          <li>Prevenir ou investigar possíveis irregularidades relacionadas ao serviço</li>
        </ul>

        <h2>4. Armazenamento e Proteção de Dados</h2>

        <h3>4.1 Segurança</h3>
        <p>Adotamos medidas de segurança apropriadas para proteger suas informações contra acesso, alteração, divulgação ou destruição não autorizados.</p>

        <h3>4.2 Retenção de Dados</h3>
        <p>Reteremos suas informações pessoais apenas pelo tempo necessário para cumprir os propósitos descritos nesta Política de Privacidade, a menos que um período de retenção mais longo seja exigido ou permitido por lei.</p>

        <h2>5. Seus Direitos</h2>

        <h3>5.1 Acesso e Correção</h3>
        <p>Você tem o direito de acessar e corrigir suas informações pessoais armazenadas conosco. Pode fazer isso através das configurações do seu perfil no aplicativo.</p>

        <h3>5.2 Exclusão de Dados</h3>
        <p>Você pode solicitar a exclusão de suas informações pessoais, sujeito a certas exceções. Para fazer isso, entre em contato conosco através dos canais de suporte.</p>

        <h2>6. Alterações nesta Política de Privacidade</h2>
        <p>Podemos atualizar nossa Política de Privacidade periodicamente. Notificaremos você sobre quaisquer mudanças publicando a nova política no aplicativo e, quando apropriado, enviaremos uma notificação por e-mail.</p>

        <h2>7. Contato</h2>
        <p>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco através de:</p>
        <ul>
          <li>E-mail: contato@linkbr.app</li>
          <li>Endereço: São Paulo, SP - Brasil</li>
        </ul>

        <p>Esta Política de Privacidade visa garantir que você tenha conhecimento de como suas informações são tratadas ao utilizar nosso aplicativo. Agradecemos por confiar na linkBR APP e nos comprometemos a proteger sua privacidade e seus dados pessoais.</p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
