import React, { useState, useEffect } from 'react';

import './styles/NavBar.css';
import '../../Mobile.css';

import navLogo from '../../../../assets/img/navLogo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

function NavBar({ simplified }) {
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setIsNavVisible(false);
      } else {
        setIsNavVisible(true);
      }
      setLastScrollY(window.scrollY);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <>
      <nav className={isNavVisible ? 'visible' : 'hidden'}>
        <div className="navbar">
          <div className="nav-brand">
            <a href="/"><img src={navLogo} alt="logotipo" /></a>
          </div>
          <div className="hamburger-menu" onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </div>
          {!simplified && (<div className={`menu-links-center ${isMenuOpen ? 'open' : ''}`}>
            <a className='nav-btn' href="#top">Oi</a>
            <a className='nav-btn' href="#nossoapp">Nosso APP</a>
            <a className='nav-btn' href="#facaparte">Faça Parte</a>
            <a className='nav-btn' href="#contato">Contato</a>
          </div>
          )}
          <div className={`menu-links-right ${isMenuOpen ? 'open' : ''}`}>
            {!simplified && (
              <>
                <a className='nav-btn' href="/login">entre</a>
                <a className='nav-btn' href="/sign">registre-se</a>
              </>
            )}
            {simplified && <a className='nav-btn privicy' href="/">voltar</a>}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
