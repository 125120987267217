import React from 'react';
import { Link } from 'react-router-dom';

import '../styles/Footer.css';

import icoInstagram from '../../../../../assets/img/icoInstagram.png';
// import icoFacebook from '../../../../../assets/img/icoFacebook.png';
// import icoLinkedin from '../../../../../assets/img/icoLinkedIn.png';
// import icoTwitter from '../../../../../assets/img/icoTwitter.png';
// import icoYoutube from '../../../../../assets/img/icoYouTube.png';
// import icoWhatsapp from '../../../../../assets/img/icoWhatsapp.png';
// import icoEmail from '../../../../../assets/img/navLogo.png';
import logoFooter from '../../../../../assets/img/navLogo.png';
import google from '../../../../../assets/img/googleStore.png';
import apple from '../../../../../assets/img/appleStore.png';

function Footer() {
  return (
    <>
      <footer id="footer" >
        <div className="footer-container">
          <div className="column A">
            <a href="#">Faça Parte</a>
            <a href="#">contato@linkbr.app</a>
            <a href="#">Trabalhe Conosco</a>
            <h5>Baixe Agora</h5>
            <div className="stores">
              <img src={google} alt="google store" />
              <img src={apple} alt="apple store" />
            </div>
          </div>
          <div className="column B">
            <img src={logoFooter} alt="logo" />
            <p>2024 - linkBr App</p>
            <p>All rights reserved</p>
          </div>
          <div className="column C">
            <p>Siga-nos</p>
            <div className="followUs">
              <Link to="https://www.instagram.com/linkbrservices/" target="blank"><img src={icoInstagram} alt="instagram" /></Link>
              {/* <img src={icoFacebook} alt="facebook" />
              <img src={icoLinkedin} alt="linkedin" />
              <img src={icoTwitter} alt="twitter" />
              <img src={icoYoutube} alt="youtube" />
              <img src={icoWhatsapp} alt="whatsapp" />
              <img src={icoEmail} alt="email" /> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer