import axios from 'axios';
import { API_URL } from '../config/url_envoiriment';

export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Se precisar enviar cookies para o backend
});


export const createSession = async (email, password) => {
  return api.post('/login', { email, password });
}

export const singnUp = async (email, first_name, last_name) => {
  return api.post('/sign', { email, first_name, last_name });
}


export const forgot = async (email) => {
  return api.post('/login/forgot', { email });
}

export const newsletter = async (data) => {
  try {
    console.log(`data to send:  ${JSON.stringify(data)}`);
    const response = await api.post('/email/newsletter', data)
    return response.data;
  } catch (error) {
    console.error('Newsletter error:', error);
    throw error;
  }
}

export const subscriber = async (formData) => {
  try {
    console.log(`data to send:  ${JSON.stringify(formData)}`);
    const response = await api.post('/email/subscriber', { formData });
    return response.data;
  } catch (error) {
    console.error('Newsletter error:', error);
    throw error;
  }
}