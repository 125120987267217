import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// Middleware
import { AuthProvider, AuthContext } from './context/auth';
// import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import LandingPage from './pages/WebSite_LandingPage';
import Login from './pages/Login/components/Login';
import Sign from './pages/Login/components/Sign';
import PrivacyPolicy from './components/PrivacyModal/styles/PrivacyPolicy/styles/PrivacyPolicy';

const AppRouter = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>
    }

    if (!authenticated) {
      return <Navigate to='/' />;
    }

    return children;
  };
  return (
    <Router>
      <AuthProvider>
        <Routes>

          {/* Rotas para o gerenciador */}
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/sign" element={<Sign />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />

        </Routes>
      </AuthProvider>
    </Router >
  );
}
export default AppRouter;