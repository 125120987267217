import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import '../styles/Section2.css';
import '../styles/Section2Animation.css';

import phoneApp from '../../../../../assets/img/phoneApp.png';
import appleStore from '../../../../../assets/img/appleStore.png';
import googlePlay from '../../../../../assets/img/googleStore.png';

function Section2() {
  const { ref: refH1, inView: inViewH1 } = useInView({ triggerOnce: false });
  const { ref: refSpan, inView: inViewSpan } = useInView({ triggerOnce: false });
  const { ref: refH2, inView: inViewH2 } = useInView({ triggerOnce: false });
  const { ref: refP, inView: inViewP } = useInView({ triggerOnce: false });
  const { ref: refImg, inView: inViewImg } = useInView({ triggerOnce: false });

  return (
    <>
      <div id="nossoapp" className="section2">
        <div className="column">
          <div className="colA">
            <h2 ref={refH1} className={inViewH1 ? 'animate-left' : ''}>1</h2>
            <h2 ref={refH2} className={inViewH2 ? 'animate-left' : ''}>app</h2>
            <span ref={refSpan} className={inViewSpan ? 'animate-right' : ''}>para</span>
          </div>
          <div className="colB">
            <span className={`span ${inViewSpan ? 'animate-right' : ''}`}>SERVIÇOS</span>
            <div className="rowArrow">
              <div className="arrow"></div>
              <div className="square"></div>
            </div>
            <span className={`span ${inViewSpan ? 'animate-right' : ''}`}>COMIDA</span>
            <div className="rowArrow">
              <div className="arrow"></div>
              <div className="square"></div>
            </div>
            <span className={`span ${inViewSpan ? 'animate-right' : ''}`}>CUIDADO E BELEZA</span>
            <div className="rowArrow">
              <div className="arrow"></div>
              <div className="square"></div>
            </div>
            <span className={`span ${inViewSpan ? 'animate-right' : ''}`}>DIVERSÃO</span>
            <div className="rowArrow">
              <div className="arrow"></div>
              <div className="square"></div>
            </div>
            <span className={`span ${inViewSpan ? 'animate-right' : ''}`}>COMUNIDADE</span>
            <div className="rowArrow">
              <div className="arrow"></div>
              <div className="square"></div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="colC">
            <img ref={refImg} src={phoneApp} alt="telefone app" className={inViewImg ? 'animate-scale' : ''} />
          </div>
          <div className="colD">
            <h4>Baixe Agora</h4>
            <div className="stores">
              <img src={appleStore} alt="Apple Store" />
              <img src={googlePlay} alt="Google Play" />
            </div>
          </div>
        </div>
      </div>
      <h2 className='more'>e MUITO MAIS</h2>
    </>
  );
}

export default Section2;
