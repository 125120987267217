import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { newsletter } from '../../../../../service/api';
import '../styles/Section3.css';
import '../styles/Section3Animation.css';

import cardAdvertising from '../../../../../assets/img/cardAdvertising.png';
import icoCard from '../../../../../assets/img/icoCard.png';
import icoPerson from '../../../../../assets/img/icoPerson.png';
import icoCashFlow from '../../../../../assets/img/icoCashFlow.png';
import icoCalendar from '../../../../../assets/img/icoCalendar.png';

import ModalForms from '../../../../../components/ConfirmationModal/ModalForms';

function Section3() {
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emailNewsletter, setEmailNewsletter] = useState('');
  const newsletter_group = "inscrição pela landing page";

  const { ref: refHeadline, inView: inViewHeadline } = useInView({ triggerOnce: false });
  const { ref: refBodyItem, inView: inViewBodyItem } = useInView({ triggerOnce: false });
  const { ref: refNewsletter, inView: inViewNewsletter } = useInView({ triggerOnce: false });

  const handleEmailNewsletter = async (event) => {
    event.preventDefault();

    try {
      await newsletter({ email: emailNewsletter, subscribe_group: newsletter_group });
      setEmailNewsletter('');
      setMessage('Subscrição realizada com sucesso, verifique sua caixa de emails.');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 13000);
    } catch (error) {
      console.error('Erro ao realizar a subscrição:', error);
      setMessage('Houve um problema ao realizar a subscrição. Tente novamente mais tarde.');
      setShowModal(true);
    }
  };

  return (
    <>
      {showModal && <ModalForms message={message} onClose={() => setShowModal(false)} />}
      <div id="facaparte" className="section3">
        <div className="column">
          <div className={`headLine ${inViewHeadline ? 'animate-left' : ''}`} ref={refHeadline}>
            <div className="patriotLine" />
            <h2>Cadastre o seu Negócio</h2>
            <div className="patriotLine" />
          </div>
          <div className={`bodyItem ${inViewBodyItem ? 'animate-scale' : ''}`} ref={refBodyItem}>
            <img className='card' src={cardAdvertising} alt="cartão publicidade" />
            <img className='card' src={cardAdvertising} alt="cartão publicidade" />
            <img className='card' src={cardAdvertising} alt="cartão publicidade" />
            <img className='card' src={cardAdvertising} alt="cartão publicidade" />
          </div>
        </div>
        <div className="column">
          <div className={`headLine ${inViewHeadline ? 'animate-left' : ''}`} ref={refHeadline}>
            <div className="patriotLine" />
            <h2>Tenha Funcionalidades que vão ajudar no seu dia a dia</h2>
            <div className="patriotLine" />
          </div>
          <div className={`bodyItem-B ${inViewBodyItem ? 'animate-right' : ''}`} ref={refBodyItem}>
            <ul>
              <li><img src={icoCard} alt="icone cartão de crédito" /> Integração com meios de pagamentos</li>
              <li><img src={icoPerson} alt="icone pessoa" /> Seus clientes / fornecedores e contatos em um clique</li>
              <li><img src={icoCalendar} alt="icone calendario" /> Ofereça e controle seus agendamentos</li>
              <li><img src={icoCashFlow} alt="icone fluxo de caixa" /> Controle o seu fluxo de caixa de maneira eficiente</li>
            </ul>
          </div>
        </div>
        <div className={`newsletter ${inViewNewsletter ? 'animate-up' : ''}`} ref={refNewsletter}>
          <span className="newsLetter">Fique por dentro de todas as novidades</span>
          <form onSubmit={handleEmailNewsletter} className='rowLetter'>
            <input
              type="email"
              name="email_newsletter"
              id="email_newsletter"
              placeholder='@'
              value={emailNewsletter}
              onChange={(e) => setEmailNewsletter(e.target.value)}
              required
            />
            <button type='submit' className='nav-btn-news'>Fique por dentro</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Section3;
