import React from 'react'

import AnimatedLink from './AnimatedLink';
import logo from '../../../assets/img/navLogo.png';
import '../styles/Login.css';
import '../styles/AnimatedLink.css';

function Login() {
  return (
    <div className="login">
      <div className="colA" />
      <div className="colB" />
      <div className="colC" />
      <div className="colD" />
      <div className="colE">
        <img src={logo} alt="logo" />
      </div>
      <div className="colF">
        <h1>Os Brasileiros e muitos outros estáo aqui,<br /> vem junto :)</h1>
        <h2>Login</h2>
        <form>
          <input type="text" placeholder="E-mail" />
          <input type="password" placeholder="Senha" />
          <button className='send-button' type="submit">entre</button>
          <AnimatedLink className="links" text="esqueci a senha " url="/#contato" />
          <p>ou</p>
          <AnimatedLink className="links" text="registre - se " url="/sign" />
          <a href="/">voltar</a>
        </form>
      </div>
    </div>
  )
}

export default Login